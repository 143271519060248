.sora-unique {
  font-family: "Sora", serif;
  font-optical-sizing: auto;
  font-weight: 400; /* You can change this to any value between 100 and 800 */
  font-style: normal;
}

@media (max-width: 1000px) {
  .content-wrapper {
    margin-top: 0px;
  }
}

@media (min-width: 1001px) {
  .content-wrapper {
    margin-top: 43px;
  }
}

.course-display {
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 43px);
}

.chat {
  position: fixed;
  right: 2px;
  width: 350px;
  height: 500px;
  bottom: 20px;
  background-color: black;
  /* color: white; */
  padding: 10px 15px 10px 15px;
  border: 1px solid #a9a9a9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 10000;
  border-radius: 5px;
  /* transition: right 0.3s ease-in-out; */
}

.chat.visible {
  right: 2px;
}

.chat.hidden {
  right: -350px;
}

.chat-toggle-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #fa4616;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 10001;
  transition: transform 0.3s ease-in-out;
}

.chat-toggle-button:hover {
  transform: scale(1.1);
}

.chat-toggle-button.hide {
  visibility: hidden;
}

.chat-toggle-button.visible {
  visibility: visible;
}

.chat-toggle-button.wiggle {
  animation: wiggle 2s ease-in-out infinite;
}

@keyframes wiggle {
  0%, 100% {
    transform: rotate(0deg);
  }
  10%, 20% {
    transform: rotate(-10deg);
  }
  15%, 25% {
    transform: rotate(10deg);
  }
  35%, 50%, 60%, 70%, 80%, 90% {
    transform: rotate(0deg);
  }
}


.floating-text {
  position: fixed;
  bottom: 15px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  font-size:medium;
  color: white;
  padding: 5px 5px;
  /* border-radius: 5px; */
  border-radius: 5px;
  display: flex;
  align-items: center;
  z-index: 999;
}

.green-circle {
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
  margin-right: 8px;
  display: inline-block;
}

.badge {
  position: absolute;
  top: -2px;
  right: -2px;
  width: 16px;
  height: 16px;
  background-color: red;
  border-radius: 50%;
  border: 2px solid white; /* Optional: adds a white border around the badge */
}


.inline-content {
  display: flex;
  align-items: center;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: red;
  transition: transform 0.2s;
}

.close-icon:hover {
  transform: scale(0.9);
}

.courses-handler {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

#cytoscape-container {
  background-color: rgb(0, 0, 0);
}

.course-filter {
  width: 15%;
  padding: 1rem;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 15px;
  width: 100%;
  padding: 0 20px;
  pointer-events: none;
  z-index: 999;
}

.generate-button {
  position: fixed;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  pointer-events: auto;
  padding: 0.5rem 1rem;
  background-color: #ebebeb;
  opacity: 1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.generate-button[disabled] {
  visibility: visible;
  opacity: 0.5;
  background-color: #ebebeb;
}

.help-button {
  position: absolute;
  bottom: 6px;
  right: 15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #393c42;
  pointer-events: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.help-button button {
  color: white;
  padding: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.help-button button:hover {
  background-color: #363c47;
}

.loading-window {
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fa4616;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 20px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
  z-index: 999;
}

.loading-window.show {
  visibility: visible;
  opacity: 1;
}

.elapsed-time {
  position: absolute;
  top: 14px;
  right: 10px;
  background-color: #fa4616;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  padding: 5px 10px;
}

.loader-container {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.loader-container.show {
  display: block;
}

@media (max-width: 1000px) and (min-width: 501px) {
  .flexImage {
    flex-direction: column; /* Reverse the order of flex items */
    align-items: center; /* Center align items horizontally */
  }
  #display-write {
    order: 3;
    width: calc(100vw - 60px);
    border-top: 0.5px solid #a9a9a9;
    /* padding-top: 30px; */
    padding-bottom: 30px;
  }

  #cytoscape-container {
    width: 100%;
    height: 80vh;
    border-radius: 8px;
  }

  .courses-handler {
    order: 2;
    width: 100%;
    height: 80vh;
  }

  .calendar-container {
    width: 100%;
    order: 3;
  }
  .selected-courses {
    order: 1;
    width: 100%;
  }

  .arrow {
    position: absolute;
    top: 0px;
    right: 40px;
    animation: arrowAnimation 1s infinite alternate;
    z-index: 10010;
    pointer-events: none;
  }

  .caption {
    font-family: "CustomFont", "Segoe UI";
    font-size: 20px;
    position: absolute;
    top: 70px;
    right: 290px;
    text-align: center;
    color: white; /* Adjust as needed */
    margin-top: 10px; /* Space between the image and the caption */
    animation: captionAnimation 1s infinite alternate;
    z-index: 10010;
    pointer-events: none;
    text-shadow: 1px -2px 7px rgba(0, 0, 0, 0.8);
  }
}

@media (min-width: 1001px) {
  .selected-courses {
    max-width: 12%;
    min-width: 12%;
    border-right: 0.5px solid #a9a9a9;
  }

  #display-write {
    max-height: 100vh;
    border-left: 0.5px solid #a9a9a9;
    position: sticky;
    top: 0;
    bottom: 0;
    flex: 1;
    max-width: 100%;
  }

  #cytoscape-container {
    width: 100%;
    height: calc(100vh - 108px);
  }
  /* .flex.flex-col.items-start.basis-full.p-0.dark\:bg-gray-800.transition-colors.duration-500 {
    max-width: 36%;
    min-width: 36%;
  } */

  .arrow {
    position: absolute;
    top: 0px;
    right: 60px;
    animation: arrowAnimation 1s infinite alternate;
    z-index: 10010;
    pointer-events: none;
  }

  .caption {
    font-family: "CustomFont", "Segoe UI";
    font-size: 20px;
    position: absolute;
    top: 70px;
    right: 310px;
    text-align: center;
    color: white; /* Adjust as needed */
    margin-top: 10px; /* Space between the image and the caption */
    animation: captionAnimation 1s infinite alternate;
    z-index: 10010;
    pointer-events: none;
    text-shadow: 1px -2px 7px rgba(0, 0, 0, 0.8);
  }
}

@media (max-width: 500px) {
  .flexImage {
    flex-direction: column; /* Reverse the order of flex items */
    align-items: center; /* Center align items horizontally */
  }
  #display-write {
    order: 3;
    width: calc(100vw - 60px);
    border-top: 0.5px solid #a9a9a9;
    /* padding-top: 30px; */
    padding-bottom: 30px;
  }

  #cytoscape-container {
    width: 100%;
    height: 80vh;
    border-radius: 8px;
  }

  .courses-handler {
    order: 2;
    width: 100%;
    height: 80vh;
  }

  .calendar-container {
    width: 100%;
    order: 3;
  }
  .selected-courses {
    order: 1;
    width: 100%;
  }

  .arrow {
    position: absolute;
    top: 0px;
    right: -70px;
    animation: arrowAnimation 1s infinite alternate;
    z-index: 10010;
    pointer-events: none;
  }

  .caption {
    font-family: "CustomFont", "Segoe UI";
    font-size: 20px;
    position: absolute;
    top: 70px;
    right: 180px;
    text-align: center;
    color: white; /* Adjust as needed */
    margin-top: 10px; /* Space between the image and the caption */
    animation: captionAnimation 1s infinite alternate;
    z-index: 10010;
    pointer-events: none;
    text-shadow: 1px -2px 7px rgba(0, 0, 0, 0.8);
  }
}

.drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 100vh;
  padding-bottom: 60px;
  background-color: #000000;
  overflow-x: hidden;
  overflow-y: visible;
  transition: 0.5s;
  padding-top: 60px;
  z-index: 10000;
  border-radius: 5px;
  box-shadow: 10px 5px 15px -8px rgba(0, 0, 0, 0.5);
}

.drawer.closed {
  left: -300px;
  box-shadow: none;
}

.drawer-close-button {
  position: absolute;
  top: 12px;
  left: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust as needed */
  z-index: 9999; /* Ensure this is below the drawer's z-index */
  display: none; /* Initially hidden */
}

.overlay.open {
  display: block; /* Show when the drawer is open */
}

.overlay.closed {
  display: none; /* Hide when the drawer is closed */
}

.nowrap {
  white-space: nowrap;
}

@keyframes arrowAnimation {
  from {
    transform: scaleY(-1) scale(0.5) rotate(42deg) translateY(0);
  }
  to {
    transform: scaleY(-1) scale(0.5) rotate(40deg) translateY(8px);
  }
}

@keyframes captionAnimation {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-8px) translateX(-3px);
  }
}

.instruction-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  /* text-decoration: underline; */
}

.instruction-headers {
  font-weight: bold;
  font-size: larger;
  line-height: 1.5;
}

.instruction-desc {
  line-height: 1.25;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: medium;
}

@media (max-width: 1000px) {
  .map-container {
    position: relative;
    border-top: 0.5px solid #a9a9a9;
    width: calc(100vw - 60px);
    padding-top: 30px;
    padding-bottom: 30px;
    max-height: 100%;
    order: 3;
  }
}

@media (min-width: 1001px) {
  .map-container {
    border-left: 0.5px solid #a9a9a9;
    max-height: calc(100vh - 43px);
    min-height: calc(100vh - 43px);
    position: fixed;
    top: 43px;
    left: 48%;
    flex: 1;
    min-width: 52%;
    max-width: 52%;
    order: 3;
  }
}

@media (max-width: 1000px) {
  .plan-container {
    position: relative;
    width: calc(100vw - 60px);
    margin-top: 30px;
    padding-bottom: 30px;
    max-height: 100%;
    border-radius: 8px;
  }
  .plan-container-container-lol {
    border-top: 0.5px solid #a9a9a9;
  }
}

@media (min-width: 1001px) {
  .plan-container {
    border-left: 0.5px solid #a9a9a9;
    max-height: calc(100vh - 43px);
    min-height: calc(100vh - 43px);
    position: fixed;
    top: 43px;
    left: 48%;
    flex: 1;
    min-width: 52%;
    max-width: 52%;
  }
}

@media (min-width: 760px) and (max-width: 964px) {
  .flex.flex-col.items-start.basis-full.p-0.dark\:bg-gray-800.transition-colors.duration-500 {
    max-width: 100%;
    width: 100%;
    margin-top: 80px;
  }
}

@media (min-width: 965px) and (max-width: 1000px) {
  .flex.flex-col.items-start.basis-full.p-0.dark\:bg-gray-800.transition-colors.duration-500 {
    max-width: 100%;
    width: 100%;
    margin-top: 30px;
  }
}

@media (max-width: 759px) {
  .flex.flex-col.items-start.basis-full.p-0.dark\:bg-gray-800.transition-colors.duration-500 {
    max-width: 100%;
    width: 100%;
    margin-top: 80px;
  }
}

@media (min-width: 1001px) {
  .flex.flex-col.items-start.basis-full.p-0.dark\:bg-gray-800.transition-colors.duration-500 {
    max-width: 36%;
    min-width: 36%;
  }
}

/* Add this to apply the Sora font to the scheduler */
.VerticalAppointment-content,
.Cell-dayOfWeek,
.Cell-dayOfMonth,
.Content-content,
.Content-title,
.Label-text {
  font-family: "Sora", serif !important;
}
