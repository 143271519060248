#search-input:focus {
    outline: none;
}
  
.search-container {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 2px 5px 2px 10px;
}

.search-icon {
  margin-right: 8px;
  color: #888;
}

.search-input {
  flex: 1;
  padding: 7px 5px;
  border: none;
  outline: none;
  background-color: transparent;
  color: #000;
}
  
.term-year-dropdown {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 7px;
  border-radius: 0 5px 5px 0;
  border-left: 1px solid #6d727e;
}

.term-year-dropdown:focus {
  outline: none;
  background-color: #e0e0e0;
}
